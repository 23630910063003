import type {EditorJsDataType} from "@/models/EditorJsData";
import type {FeaturesType} from "@/models/User/features";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  EditorJSDataType: EditorJsDataType;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** Enabled platform features for user  */
  FeaturesType: FeaturesType;
  CloudPaymentsWidgetOptions: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   * 
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
};

export type Query = {
  _debug?: Maybe<DjangoDebug>;
  freeQuiz?: Maybe<QuizType>;
  saleQuiz?: Maybe<QuizType>;
  surveys?: Maybe<Array<Maybe<SurveyType>>>;
  promotions?: Maybe<Array<Maybe<PromotionType>>>;
  categories?: Maybe<CategoryTypeConnection>;
  streams?: Maybe<LiveStreamTypeConnection>;
  stream?: Maybe<LiveStreamType>;
  features?: Maybe<Scalars['FeaturesType']>;
  banner?: Maybe<BannerType>;
  xyzPlusContents?: Maybe<XyzPlusContentTypeConnection>;
  xyzPlusContent?: Maybe<XyzPlusContentType>;
  currentAssignment?: Maybe<AssignmentType>;
  assignmentMessages?: Maybe<Array<Maybe<AssignmentMessageType>>>;
  assignmentGallery?: Maybe<AssignmentTypeConnection>;
  assignments?: Maybe<AssignmentTypeConnection>;
  assignment?: Maybe<AssignmentType>;
  enrollments?: Maybe<EnrollmentTypeConnection>;
  enrollment?: Maybe<EnrollmentType>;
  courses?: Maybe<CourseTypeConnection>;
  course?: Maybe<CourseType>;
  commonDiscordServer?: Maybe<DiscordServerType>;
  xyzPlus?: Maybe<XyzPlusType>;
  currentUser?: Maybe<UserType>;
};


export type QueryCategoriesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryStreamsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryStreamArgs = {
  id: Scalars['ID'];
};


export type QueryBannerArgs = {
  position: BannerPosition;
};


export type QueryXyzPlusContentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  relatedCourse?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['ID']>;
  title_Icontains?: Maybe<Scalars['String']>;
};


export type QueryXyzPlusContentArgs = {
  id: Scalars['ID'];
};


export type QueryCurrentAssignmentArgs = {
  contentId: Scalars['ID'];
  enrollmentId: Scalars['ID'];
};


export type QueryAssignmentMessagesArgs = {
  contentId: Scalars['ID'];
  enrollmentId: Scalars['ID'];
};


export type QueryAssignmentGalleryArgs = {
  contentId: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAssignmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAssignmentArgs = {
  assignmentId?: Maybe<Scalars['ID']>;
};


export type QueryEnrollmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEnrollmentArgs = {
  id: Scalars['ID'];
};


export type QueryCoursesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  scope: Scalars['String'];
};


export type QueryCourseArgs = {
  slug: Scalars['String'];
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
};

export type QuizType = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  descriptionShort: Scalars['String'];
  questions: Array<QuizQuestionType>;
};

export type QuizQuestionType = {
  id?: Maybe<Scalars['Int']>;
  question: Scalars['String'];
  isMultiselect: Scalars['Boolean'];
  /** Вопрос */
  answers: Array<QuizQuestionAnswerType>;
};

export type QuizQuestionAnswerType = {
  id?: Maybe<Scalars['Int']>;
  /** Вопрос */
  question: QuizQuestionType;
  title?: Maybe<Scalars['String']>;
  answer: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type SurveyType = {
  text: Scalars['String'];
  studentName: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  courses?: Maybe<Array<Maybe<CourseType>>>;
};

export type CourseType = Node & {
  slug: Scalars['String'];
  contentKind: CourseContentKind;
  /** Ссылка на страницу c подробным описанием курса на лендинге */
  infoUrl?: Maybe<Scalars['String']>;
  /** Ссылка на страницу спецпредложения для студентов INTRO курсов */
  specialOfferUrl?: Maybe<Scalars['String']>;
  /** Ссылка на страницу, где можно произвести оплату выбранного курса */
  purchaseUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  materials?: Maybe<Scalars['EditorJSDataType']>;
  dateStart?: Maybe<Scalars['Date']>;
  duration?: Maybe<Scalars['Int']>;
  categories?: Maybe<Array<Maybe<CategoryType>>>;
  status: CourseStatus;
  isFree: Scalars['Boolean'];
  /** Текущая цена курса. Не актуально! Цена должна рассчитываться на основе "базовой цены" и активных акций. Для вывода цены с учетом акции это поле должно быть пустым. */
  price?: Maybe<Scalars['Int']>;
  /** Базовая цена курса, без учета каких-либо скидок */
  oldPrice?: Maybe<Scalars['Int']>;
  guild?: Maybe<DiscordServerType>;
  preview?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  totalContents: Scalars['Int'];
  totalHomeworks: Scalars['Int'];
  popularity?: Maybe<Scalars['Int']>;
  isShowedOnSale?: Maybe<Scalars['Boolean']>;
  marketingDescription?: Maybe<Scalars['String']>;
  courseTarget?: Maybe<Scalars['String']>;
  willLearn?: Maybe<Scalars['String']>;
  careerPath?: Maybe<Scalars['String']>;
  /** Отображается на school-xyz.com */
  difficulty: CourseDifficulty;
  telegramChannel?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['ID'];
  promotion?: Maybe<PromotionType>;
  courseKind: CourseKindType;
  courseClass?: Maybe<CourseClassType>;
  access: CourseAccess;
  lecture?: Maybe<CourseLectureType>;
  enrollment?: Maybe<EnrollmentType>;
  courses?: Maybe<Array<CourseType>>;
  questions: Array<QuestionType>;
  sections: Array<CourseSectionType>;
  userHasEditPerm?: Maybe<Scalars['Boolean']>;
  userHasFullAccess?: Maybe<Scalars['Boolean']>;
  discordServer?: Maybe<DiscordServerType>;
};


export type CourseTypeLectureArgs = {
  lectureId: Scalars['ID'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** An enumeration. */
export const CourseContentKind = {
  /** Разделы */
  Sections: 'SECTIONS',
  /** Курсы */
  Courses: 'COURSES',
  /** Интро */
  Intro: 'INTRO'
} as const;

export type CourseContentKind = typeof CourseContentKind[keyof typeof CourseContentKind];


export type CategoryType = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  title: Scalars['String'];
  descriptionShort?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export const CourseStatus = {
  /** Драфт */
  D: 'D',
  /** Отладка */
  G: 'G',
  /** Анонс */
  A: 'A',
  /** Начат */
  S: 'S',
  /** Закончен */
  E: 'E'
} as const;

export type CourseStatus = typeof CourseStatus[keyof typeof CourseStatus];
export type DiscordServerType = {
  id: Scalars['String'];
  name: Scalars['String'];
  inviteUrl: Scalars['String'];
  isJoined: Scalars['Boolean'];
};

/** An enumeration. */
export const CourseDifficulty = {
  /** с нуля */
  FromZero: 'FROM_ZERO',
  /** для начинающих */
  ForBeginner: 'FOR_BEGINNER',
  /** для продвинутых */
  ForAdvanced: 'FOR_ADVANCED'
} as const;

export type CourseDifficulty = typeof CourseDifficulty[keyof typeof CourseDifficulty];
export type PromotionType = {
  id: Scalars['ID'];
  promotionType: PromotionPromotionType;
  /** Публичное название акции, которое будет отображаться студентам */
  publicTitle?: Maybe<Scalars['String']>;
  /** Ссылка на акцию. По умолчанию: ссылка на все курсы */
  url: Scalars['String'];
  /** Цвет, используемый для оформления акции (стикеры, фон и т.д.). */
  color: Scalars['String'];
  /** Текст уведомления об акции, который будет показан пользователю */
  notificationText: Scalars['String'];
  endDate: Scalars['DateTime'];
  /** Размер скидки указывается целым числом от 0 до 100 */
  discount: Scalars['Int'];
  preview?: Maybe<Scalars['String']>;
  /** Сообщение для стикера "подарок" */
  gift: Scalars['String'];
};

/** An enumeration. */
export const PromotionPromotionType = {
  /** Все курсы */
  AllCourses: 'ALL_COURSES',
  /** Выбрать курсы */
  Course: 'COURSE'
} as const;

export type PromotionPromotionType = typeof PromotionPromotionType[keyof typeof PromotionPromotionType];

export type CourseKindType = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  label: Scalars['String'];
};

export type CourseClassType = Node & {
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type CourseAccess = {
  view: Scalars['Boolean'];
  isEnrolled: Scalars['Boolean'];
};

export type CourseLectureType = {
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  contents: Array<CourseContentType>;
  content?: Maybe<CourseContentType>;
  isRated?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  blockedByLecture?: Maybe<CourseLectureType>;
  blockedBySection?: Maybe<CourseSectionType>;
  unlockDate?: Maybe<Scalars['DateTime']>;
  lockedByCourseClass?: Maybe<Scalars['Boolean']>;
  section?: Maybe<CourseSectionType>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  hasEditPerm?: Maybe<Scalars['Boolean']>;
};


export type CourseLectureTypeContentArgs = {
  contentId: Scalars['ID'];
};

export type CourseContentType = {
  id: Scalars['ID'];
  title: Scalars['String'];
  type: ContentType;
  pk: Scalars['ID'];
  isCompleted?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  isArchive?: Maybe<Scalars['Boolean']>;
  unlockDate?: Maybe<Scalars['DateTime']>;
  body?: Maybe<Scalars['EditorJSDataType']>;
  assignmentGallery?: Maybe<Array<Maybe<AssignmentType>>>;
  canSendAssignment?: Maybe<Scalars['Boolean']>;
  userHasEditPerm?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export const ContentType = {
  /** Видео */
  V: 'V',
  /** Материал */
  M: 'M',
  /** Вопросы */
  Q: 'Q',
  /** Частые Ошибки */
  F: 'F',
  /** Упражнение */
  E: 'E',
  /** Задание */
  A: 'A'
} as const;

export type ContentType = typeof ContentType[keyof typeof ContentType];
export type AssignmentType = Node & {
  body?: Maybe<Scalars['EditorJSDataType']>;
  status: AssignmentStatus;
  dateSubmitted: Scalars['DateTime'];
  dateChecked?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  awaitingCheck?: Maybe<Scalars['Int']>;
  content?: Maybe<CourseContentType>;
  user: UserType;
  course: CourseType;
  lecture: CourseLectureType;
  messages?: Maybe<Array<Maybe<AssignmentMessageType>>>;
};

/** An enumeration. */
export const AssignmentStatus = {
  /** На Проверке */
  P: 'P',
  /** Требует Исправления */
  F: 'F',
  /** Работа выполнена */
  D: 'D',
  /** Работа нe была выполнена */
  W: 'W'
} as const;

export type AssignmentStatus = typeof AssignmentStatus[keyof typeof AssignmentStatus];
export type UserType = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** Отметьте, если пользователь может входить в административную часть сайта. */
  isStaff: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Изначально поле берется из дискорда. В журнале есть возможность сменить свой ник. */
  username: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  middleName: Scalars['String'];
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<MemberGender>;
  isAgreementAccepted: Scalars['Boolean'];
  isDiscordRequired: Scalars['Boolean'];
  agreementUpdates: Array<AgreementUpdatesType>;
  awaitingAssignment?: Maybe<Scalars['Int']>;
  loggedAsUser?: Maybe<UserType>;
};

export const MemberGender = {
  Male: 'MALE',
  Female: 'FEMALE',
  Unknown: 'UNKNOWN'
} as const;

export type MemberGender = typeof MemberGender[keyof typeof MemberGender];
export type AgreementUpdatesType = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  updateDate: Scalars['Date'];
  body?: Maybe<Scalars['EditorJSDataType']>;
};

export type AssignmentMessageType = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  awaitingTime: Scalars['Int'];
  status?: Maybe<AssignmentMessageStatus>;
  user: UserType;
  assignment: AssignmentType;
  body?: Maybe<Scalars['EditorJSDataType']>;
};

/** An enumeration. */
export const AssignmentMessageStatus = {
  /** На Проверке */
  P: 'P',
  /** Требует Исправления */
  F: 'F',
  /** Работа выполнена */
  D: 'D',
  /** Работа нe была выполнена */
  W: 'W'
} as const;

export type AssignmentMessageStatus = typeof AssignmentMessageStatus[keyof typeof AssignmentMessageStatus];
export type CourseSectionType = {
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  lectures: Array<CourseLectureType>;
  lockedByCourseClass?: Maybe<Scalars['Boolean']>;
  unlockDate?: Maybe<Scalars['DateTime']>;
  lecturesCount?: Maybe<Scalars['Int']>;
  lecturesCompletedCount?: Maybe<Scalars['Int']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  hasEditPerm?: Maybe<Scalars['Boolean']>;
};

export type EnrollmentType = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  course: CourseType;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  enrollments?: Maybe<Array<EnrollmentType>>;
  progress?: Maybe<ProgressType>;
  state?: Maybe<State>;
  nextCourse?: Maybe<NextCourseType>;
  canBuyHomework: Scalars['Boolean'];
  isFrozen?: Maybe<Scalars['Boolean']>;
};

/** Info about progress of current student  */
export type ProgressType = {
  completion?: Maybe<Scalars['Float']>;
  totalHomeworks?: Maybe<Scalars['Int']>;
  completedHomeworks?: Maybe<Scalars['Int']>;
  expectedProgress?: Maybe<Scalars['Float']>;
};

/** An enumeration. */
export const State = {
  IsAwaitingStart: 'IS_AWAITING_START',
  IsActive: 'IS_ACTIVE',
  IsCompleted: 'IS_COMPLETED',
  IsNotAccessed: 'IS_NOT_ACCESSED',
  IsFrozen: 'IS_FROZEN'
} as const;

export type State = typeof State[keyof typeof State];
export type NextCourseType = {
  course: CourseType;
  startDate?: Maybe<Scalars['Date']>;
};

export type QuestionType = {
  text: Scalars['String'];
  answer?: Maybe<Scalars['EditorJSDataType']>;
};

export type CategoryTypeConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoryTypeEdge>>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** A Relay edge containing a `CategoryType` and its cursor. */
export type CategoryTypeEdge = {
  /** The item at the end of the edge */
  node?: Maybe<CategoryType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LiveStreamTypeConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LiveStreamTypeEdge>>;
};

/** A Relay edge containing a `LiveStreamType` and its cursor. */
export type LiveStreamTypeEdge = {
  /** The item at the end of the edge */
  node?: Maybe<LiveStreamType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LiveStreamType = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  status: LiveStreamStatus;
  title: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  /** На Kinescope, Youtube или Vimeo */
  url: Scalars['String'];
  courses?: Maybe<Array<CourseType>>;
  description: Scalars['String'];
  startDate: Scalars['DateTime'];
  body?: Maybe<Scalars['EditorJSDataType']>;
  chatUrl?: Maybe<Scalars['String']>;
  pk: Scalars['ID'];
  access: LiveStreamAccess;
};

/** An enumeration. */
export const LiveStreamStatus = {
  /** Черновик */
  Draft: 'DRAFT',
  /** Опубликован */
  Published: 'PUBLISHED'
} as const;

export type LiveStreamStatus = typeof LiveStreamStatus[keyof typeof LiveStreamStatus];
export type LiveStreamAccess = {
  view: Scalars['Boolean'];
};


export type BannerType = {
  id: Scalars['ID'];
  position: BannerPosition;
  /** Куда ведет баннер */
  url: Scalars['String'];
  /** Размер изображения 1320 x 198 пикселей. Допустимые форматы JPG и PNG. Максимально допустимый вес 150 КБ. */
  desktopImage: Scalars['String'];
  /** Размер изображения 738 x 415 пикселей. Допустимые форматы JPG и PNG. Максимально допустимый вес 100 КБ. */
  mobileImage: Scalars['String'];
};

/** An enumeration. */
export const BannerPosition = {
  /** Дашборд */
  Dashboard: 'DASHBOARD',
  /** Страница курса */
  Course: 'COURSE'
} as const;

export type BannerPosition = typeof BannerPosition[keyof typeof BannerPosition];
export type XyzPlusContentTypeConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<XyzPlusContentTypeEdge>>;
};

/** A Relay edge containing a `XyzPlusContentType` and its cursor. */
export type XyzPlusContentTypeEdge = {
  /** The item at the end of the edge */
  node?: Maybe<XyzPlusContentType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type XyzPlusContentType = Node & {
  /** The ID of the object. */
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  status: XyzPlusContentStatus;
  access: XyzPlusContentAccess;
  body?: Maybe<Scalars['EditorJSDataType']>;
  relatedCourse?: Maybe<CourseType>;
  category?: Maybe<CategoryType>;
  isNew: Scalars['Boolean'];
};

/** An enumeration. */
export const XyzPlusContentStatus = {
  /** Черновик */
  Draft: 'DRAFT',
  /** Опубликован */
  Published: 'PUBLISHED'
} as const;

export type XyzPlusContentStatus = typeof XyzPlusContentStatus[keyof typeof XyzPlusContentStatus];
export type XyzPlusContentAccess = {
  view: Scalars['Boolean'];
};

export type AssignmentTypeConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssignmentTypeEdge>>;
};

/** A Relay edge containing a `AssignmentType` and its cursor. */
export type AssignmentTypeEdge = {
  /** The item at the end of the edge */
  node?: Maybe<AssignmentType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type EnrollmentTypeConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EnrollmentTypeEdge>>;
};

/** A Relay edge containing a `EnrollmentType` and its cursor. */
export type EnrollmentTypeEdge = {
  /** The item at the end of the edge */
  node?: Maybe<EnrollmentType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseTypeConnection = {
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseTypeEdge>>;
};

/** A Relay edge containing a `CourseType` and its cursor. */
export type CourseTypeEdge = {
  /** The item at the end of the edge */
  node?: Maybe<CourseType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** State of XYZ+ subscription for current User.  */
export type XyzPlusType = {
  xyzPlusExpirationDate?: Maybe<Scalars['Date']>;
  xyzPlusStatus: XyzPlusStatusType;
  isXyzPlusActive: Scalars['Boolean'];
  categories: Array<CategoryType>;
  tariffs: Array<XyzPlusTariffType>;
  currentSubscription?: Maybe<XyzPlusSubscriptionType>;
  courses?: Maybe<Array<Maybe<CourseType>>>;
};

export const XyzPlusStatusType = {
  Empty: 'EMPTY',
  Active: 'ACTIVE',
  Expired: 'EXPIRED'
} as const;

export type XyzPlusStatusType = typeof XyzPlusStatusType[keyof typeof XyzPlusStatusType];
export type XyzPlusTariffType = {
  kind: XyzPlusSubscriptionTariff;
  title: Scalars['String'];
  duration: Scalars['String'];
  discount: Scalars['Int'];
  price: Scalars['Int'];
  priceWithDiscount: Scalars['Int'];
  monthlyPrice: Scalars['Int'];
  trial?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export const XyzPlusSubscriptionTariff = {
  /** Indie */
  Lite: 'LITE',
  /** AA */
  Best: 'BEST',
  /** AAA */
  Pro: 'PRO'
} as const;

export type XyzPlusSubscriptionTariff = typeof XyzPlusSubscriptionTariff[keyof typeof XyzPlusSubscriptionTariff];
export type XyzPlusSubscriptionType = {
  status: XyzPlusSubscriptionStatus;
  tariff: XyzPlusSubscriptionTariff;
  /** Дата и время следующего платежа во временной зоне UTC */
  nextTransactionDate?: Maybe<Scalars['DateTime']>;
  tariffTitle: Scalars['String'];
  duration: Scalars['String'];
  priceWithDiscount: Scalars['Int'];
  monthlyPrice: Scalars['Int'];
  prepaidDuration: Scalars['String'];
};

/** An enumeration. */
export const XyzPlusSubscriptionStatus = {
  /** Активна */
  Active: 'ACTIVE',
  /** Просрочена */
  Pastdue: 'PASTDUE',
  /** Отменена */
  Cancelled: 'CANCELLED',
  /** Отклонена */
  Rejected: 'REJECTED',
  /** Завершена */
  Expired: 'EXPIRED'
} as const;

export type XyzPlusSubscriptionStatus = typeof XyzPlusSubscriptionStatus[keyof typeof XyzPlusSubscriptionStatus];
/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type Mutation = {
  updateLecture?: Maybe<CourseLectureMutation>;
  /** Creating Invoice according selected tariff.  */
  createXyzPlusSubscriptionInvoice?: Maybe<XyzPlusSubscriptionInvoiceMutation>;
  updateUserContactInfo?: Maybe<UserContactInfoMutation>;
  updateUserAgreement?: Maybe<UserAgreementMutation>;
  saveForm?: Maybe<FormDataSubmitMutation>;
  phoneVerification?: Maybe<PhoneVerification>;
  profilePhoneVerification?: Maybe<ProfilePhoneVerification>;
  requestAuth?: Maybe<RequestAuth>;
  markContentComplete?: Maybe<CourseContentType>;
  sendAssignment?: Maybe<AssignmentType>;
  sendAssignmentMessage?: Maybe<AssignmentMessageType>;
  rateLecture?: Maybe<CourseLectureType>;
};


/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type MutationUpdateLectureArgs = {
  input?: Maybe<CourseLectureDto>;
};


/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type MutationCreateXyzPlusSubscriptionInvoiceArgs = {
  input?: Maybe<TariffDto>;
};


/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type MutationUpdateUserContactInfoArgs = {
  input?: Maybe<UserContactInfoDto>;
};


/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type MutationUpdateUserAgreementArgs = {
  input?: Maybe<AcceptAgreementDto>;
};


/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type MutationSaveFormArgs = {
  input?: Maybe<FormDataSubmitInput>;
};


/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type MutationPhoneVerificationArgs = {
  input?: Maybe<PhoneVerificationDto>;
};


/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type MutationProfilePhoneVerificationArgs = {
  input?: Maybe<ProfilePhoneVerificationDto>;
};


/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type MutationRequestAuthArgs = {
  input?: Maybe<RequestAuthDto>;
};


/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type MutationMarkContentCompleteArgs = {
  input?: Maybe<ContentProgressDto>;
};


/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type MutationSendAssignmentArgs = {
  input?: Maybe<AssignmentDto>;
};


/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type MutationSendAssignmentMessageArgs = {
  input?: Maybe<AssignmentMessageDto>;
};


/**
 * For development we user DDT with gql plugin, keep in mind:
 * current DDT version has problem: https://github.com/flavors/django-graphiql-debug-toolbar/issues/9
 * which provide mutation applying twice.
 */
export type MutationRateLectureArgs = {
  input?: Maybe<LectureRatingDto>;
};

export type CourseLectureMutation = {
  lecture?: Maybe<CourseLectureType>;
};

export type CourseLectureDto = {
  id: Scalars['ID'];
  contents: Array<Scalars['ID']>;
};

/** Creating Invoice according selected tariff.  */
export type XyzPlusSubscriptionInvoiceMutation = {
  xyzPlusSubscriptionInvoice?: Maybe<InvoiceType>;
};

export type InvoiceType = {
  cpWidgetData?: Maybe<Scalars['CloudPaymentsWidgetOptions']>;
};


export type TariffDto = {
  tariff: Tariff;
};

/** An enumeration. */
export const Tariff = {
  Lite: 'LITE',
  Best: 'BEST',
  Pro: 'PRO'
} as const;

export type Tariff = typeof Tariff[keyof typeof Tariff];
export type UserContactInfoMutation = {
  userContactInfo?: Maybe<UserContactInfoType>;
};

export type UserContactInfoType = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  contactPhone?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  middleName: Scalars['String'];
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<MemberGender>;
};

export type UserContactInfoDto = {
  contactPhone?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  gender?: Maybe<MemberGender>;
  birthDate?: Maybe<Scalars['Date']>;
};

export type UserAgreementMutation = {
  isAgreementAccepted?: Maybe<Scalars['Boolean']>;
};

export type AcceptAgreementDto = {
  isAccepted?: Maybe<Scalars['Boolean']>;
};

export type FormDataSubmitMutation = {
  registrationId?: Maybe<Scalars['String']>;
};

export type FormDataSubmitInput = {
  formType: FormDataTypeEnum;
  data: DefaultFormDataInput;
  quizData?: Maybe<QuizFormDataInput>;
  courseId?: Maybe<Scalars['Int']>;
  marketingData?: Maybe<Scalars['JSONString']>;
  emailsConsent?: Maybe<Scalars['Boolean']>;
  dataTreatmentConsent?: Maybe<Scalars['Boolean']>;
};

export const FormDataTypeEnum = {
  Quiz: 'QUIZ',
  Consultation: 'CONSULTATION',
  DemoRegistration: 'DEMO_REGISTRATION',
  IntroRegistration: 'INTRO_REGISTRATION'
} as const;

export type FormDataTypeEnum = typeof FormDataTypeEnum[keyof typeof FormDataTypeEnum];
export type DefaultFormDataInput = {
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
};

export type QuizFormDataInput = {
  id: Scalars['Int'];
  answerIds: Array<Scalars['Int']>;
};


export type PhoneVerification = {
  verificationId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['String']>;
};

export type PhoneVerificationDto = {
  phone: Scalars['String'];
  recaptchaToken: Scalars['String'];
  isProcessingAgreed: Scalars['Boolean'];
  isAdvertisingAgreed: Scalars['Boolean'];
};

export type ProfilePhoneVerification = {
  verificationId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['String']>;
};

export type ProfilePhoneVerificationDto = {
  phone: Scalars['String'];
};

export type RequestAuth = {
  success?: Maybe<Scalars['Boolean']>;
};

export type RequestAuthDto = {
  code: Scalars['String'];
  verificationId: Scalars['String'];
};

export type ContentProgressDto = {
  contentId: Scalars['ID'];
};

export type AssignmentDto = {
  body: Scalars['JSONString'];
  contentId: Scalars['ID'];
  keepAssigned?: Maybe<Scalars['Boolean']>;
};

export type AssignmentMessageDto = {
  body: Scalars['JSONString'];
  contentId: Scalars['ID'];
};

export type LectureRatingDto = {
  lectureId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  rating: Scalars['Float'];
};

export type AmoWidgetFragment = Pick<UserType, 'id' | 'username'>;

export type RequestAuthMutationVariables = Exact<{
  input: RequestAuthDto;
}>;


export type RequestAuthMutation = { requestAuth?: Maybe<Pick<RequestAuth, 'success'>> };

export type PhoneVerificationMutationVariables = Exact<{
  input: PhoneVerificationDto;
}>;


export type PhoneVerificationMutation = { phoneVerification?: Maybe<Pick<PhoneVerification, 'verificationId' | 'expiredAt'>> };

export type FaqItemFragment = Pick<QuestionType, 'text' | 'answer'>;

export type FaqListFragment = { questions: Array<FaqItemFragment> };

export type PageLayoutQueryVariables = Exact<{ [key: string]: never; }>;


export type PageLayoutQuery = (
  { currentUser?: Maybe<(
    Pick<UserType, 'isAgreementAccepted'>
    & AmoWidgetFragment
    & PageLayoutHeaderCurrentUserFragment
  )> }
  & CurrentUserProviderFragment
);

export type PageLayoutHeaderCurrentUserFragment = Pick<UserType, 'username' | 'avatar' | 'isAgreementAccepted' | 'isStaff' | 'awaitingAssignment'>;

export type StaffEditBtnQueryVariables = Exact<{ [key: string]: never; }>;


export type StaffEditBtnQuery = { currentUser?: Maybe<Pick<UserType, 'isStaff'>> };

export type CheckerAssignmentsListQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
}>;


export type CheckerAssignmentsListQuery = { assignments?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<AssignmentType, 'id' | 'awaitingCheck' | 'dateSubmitted' | 'dateChecked'>
        & { course: Pick<CourseType, 'title'>, user: Pick<UserType, 'avatar' | 'username'>, lecture: Pick<CourseLectureType, 'title'>, content?: Maybe<Pick<CourseContentType, 'title'>> }
      )> }>> }> };

export type BannerFragment = Pick<BannerType, 'url' | 'desktopImage' | 'mobileImage'>;

export type CourseCardControlsFragment = (
  Pick<CourseType, 'slug' | 'isFree' | 'infoUrl' | 'purchaseUrl'>
  & { access: Pick<CourseAccess, 'view'> }
);

export type CourseCardPriceFragment = Pick<CourseType, 'price' | 'oldPrice' | 'isFree'>;

export type CourseCardPromoFragment = (
  Pick<CourseType, 'id' | 'slug' | 'isFree' | 'thumbnail' | 'title' | 'infoUrl' | 'purchaseUrl'>
  & { courseKind: Pick<CourseKindType, 'label'>, access: Pick<CourseAccess, 'view'> }
  & CourseCardControlsFragment
  & CourseCardPriceFragment
);

export type UpdateLectureMutationVariables = Exact<{
  input: CourseLectureDto;
}>;


export type UpdateLectureMutation = { updateLecture?: Maybe<{ lecture?: Maybe<(
      Pick<CourseLectureType, 'id'>
      & { contents: Array<Pick<CourseContentType, 'id'>> }
    )> }> };

export type CommonDiscordServerInformerFragment = Pick<DiscordServerType, 'inviteUrl'>;

export type EnrollmentBundleCardFragment = { course: (
    Pick<CourseType, 'title'>
    & { courseKind: Pick<CourseKindType, 'label'>, courses?: Maybe<Array<(
      Pick<CourseType, 'id' | 'isFree'>
      & EnrollmentBundleCourseCardFragment
    )>> }
  ), enrollments?: Maybe<Array<(
    Pick<EnrollmentType, 'startDate' | 'endDate'>
    & { course: Pick<CourseType, 'id' | 'isFree'> }
    & EnrollmentCardEnrolledFragment
  )>>, nextCourse?: Maybe<(
    Pick<NextCourseType, 'startDate'>
    & { course: Pick<CourseType, 'id'> }
  )> };

export type EnrollmentBundleCourseCardFragment = (
  Pick<CourseType, 'thumbnail' | 'title'>
  & { courseKind: Pick<CourseKindType, 'label'> }
);

export type EnrollmentCardFragment = (
  { course: Pick<CourseType, 'contentKind'> }
  & EnrollmentCardEnrolledFragment
  & EnrollmentBundleCardFragment
);

export type EnrollmentCardEnrolledFragment = (
  Pick<EnrollmentType, 'id' | 'startDate'>
  & { course: (
    Pick<CourseType, 'slug' | 'thumbnail' | 'title' | 'isFree'>
    & { courseKind: Pick<CourseKindType, 'label'> }
  ) }
  & EnrollmentStatusFragment
  & EnrollmentDateFragment
  & EnrollmentProgressFragment
);

export type EnrollmentDateFragment = Pick<EnrollmentType, 'startDate' | 'endDate'>;

export type UserIdQueryVariables = Exact<{ [key: string]: never; }>;


export type UserIdQuery = { currentUser?: Maybe<Pick<UserType, 'id'>> };

export type EnrollmentProgressFragment = (
  Pick<EnrollmentType, 'state' | 'canBuyHomework'>
  & { course: Pick<CourseType, 'slug'>, progress?: Maybe<Pick<ProgressType, 'completion' | 'expectedProgress' | 'completedHomeworks' | 'totalHomeworks'>> }
);

export type EnrollmentStatusFragment = Pick<EnrollmentType, 'id' | 'startDate' | 'endDate' | 'state'>;

export type StreamCardFragment = (
  Pick<LiveStreamType, 'id' | 'title' | 'thumbnail' | 'description' | 'startDate'>
  & { courses?: Maybe<Array<Pick<CourseType, 'id' | 'title'>>> }
);

export type StreamsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
}>;


export type StreamsQuery = { streams?: Maybe<{ edges: Array<Maybe<{ node?: Maybe<(
        Pick<LiveStreamType, 'id' | 'startDate'>
        & { access: Pick<LiveStreamAccess, 'view'> }
        & StreamCardFragment
      )> }>> }> };

export type CurrentUserProviderFragment = Pick<Query, 'features'>;

export type ContactInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactInfoQuery = { currentUser?: Maybe<Pick<UserType, 'firstName' | 'lastName' | 'middleName' | 'contactPhone' | 'contactEmail' | 'gender'>> };

export type UpdateUserContactInfoMutationVariables = Exact<{
  input?: Maybe<UserContactInfoDto>;
}>;


export type UpdateUserContactInfoMutation = { updateUserContactInfo?: Maybe<{ userContactInfo?: Maybe<Pick<UserContactInfoType, 'firstName' | 'lastName' | 'middleName' | 'contactEmail' | 'gender'>> }> };

export type UserDiscordControlFragment = Pick<UserType, 'username' | 'avatar' | 'isDiscordRequired'>;

export type UserPhoneFormFragment = Pick<UserType, 'phone' | 'contactPhone'>;

export type ProfilePhoneVerificationMutationVariables = Exact<{
  input: ProfilePhoneVerificationDto;
}>;


export type ProfilePhoneVerificationMutation = { profilePhoneVerification?: Maybe<Pick<ProfilePhoneVerification, 'verificationId' | 'expiredAt'>> };

export type XyzPlusContentCardFragment = (
  Pick<XyzPlusContentType, 'id' | 'title' | 'thumbnail' | 'isNew' | 'status'>
  & { relatedCourse?: Maybe<Pick<CourseType, 'title'>>, category?: Maybe<Pick<CategoryType, 'title'>> }
);

export type XyzPlusContentsCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type XyzPlusContentsCategoriesQuery = { xyzPlus?: Maybe<{ categories: Array<Pick<CategoryType, 'id' | 'title'>>, courses?: Maybe<Array<Maybe<Pick<CourseType, 'id' | 'title'>>>> }> };

export type XyzPlusContentsQueryVariables = Exact<{
  category?: Maybe<Scalars['ID']>;
  relatedCourse?: Maybe<Scalars['ID']>;
  title_Icontains?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type XyzPlusContentsQuery = { xyzPlusContents?: Maybe<{ pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>, edges: Array<Maybe<(
      Pick<XyzPlusContentTypeEdge, 'cursor'>
      & { node?: Maybe<(
        Pick<XyzPlusContentType, 'id'>
        & XyzPlusContentCardFragment
      )> }
    )>> }> };

export type XyzPlusStatusInformerFragment = (
  Pick<XyzPlusType, 'xyzPlusExpirationDate' | 'xyzPlusStatus'>
  & { currentSubscription?: Maybe<Pick<XyzPlusSubscriptionType, 'status'>> }
);

export type CreateXyzPlusSubscriptionInvoiceMutationVariables = Exact<{
  input: TariffDto;
}>;


export type CreateXyzPlusSubscriptionInvoiceMutation = { createXyzPlusSubscriptionInvoice?: Maybe<{ xyzPlusSubscriptionInvoice?: Maybe<Pick<InvoiceType, 'cpWidgetData'>> }> };

export type IsXyzPlusActiveQueryVariables = Exact<{ [key: string]: never; }>;


export type IsXyzPlusActiveQuery = { xyzPlus?: Maybe<Pick<XyzPlusType, 'isXyzPlusActive'>> };

export type DashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardQuery = (
  { banner?: Maybe<BannerFragment> }
  & DashboardXyzPlusFragment
);

export type DashboardAllQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardAllQuery = { draftCourses?: Maybe<DashboardCoursesListFragment>, availableCourses?: Maybe<DashboardCoursesListFragment>, archiveCourses?: Maybe<DashboardCoursesListFragment> };

export type DashboardCoursesListFragment = { edges: Array<Maybe<{ node?: Maybe<(
      Pick<CourseType, 'id'>
      & CourseCardPromoFragment
    )> }>> };

export type DashboardEnrollmentsListFragment = { edges: Array<Maybe<{ node?: Maybe<EnrollmentCardFragment> }>> };

export type DashboardMyQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardMyQuery = { enrollments?: Maybe<DashboardEnrollmentsListFragment>, currentUser?: Maybe<Pick<UserType, 'isDiscordRequired'>>, recomendedCourses?: Maybe<DashboardCoursesListFragment>, staffCourses?: Maybe<DashboardCoursesListFragment>, reviewCourses?: Maybe<DashboardCoursesListFragment> };

export type DashboardXyzPlusFragment = { xyzPlus?: Maybe<Pick<XyzPlusType, 'isXyzPlusActive'>> };

export type PageAgreementQueryVariables = Exact<{ [key: string]: never; }>;


export type PageAgreementQuery = { currentUser?: Maybe<{ agreementUpdates: Array<Pick<AgreementUpdatesType, 'id' | 'body' | 'updateDate'>> }> };

export type UpdateUserAgreementMutationVariables = Exact<{
  input: AcceptAgreementDto;
}>;


export type UpdateUserAgreementMutation = { updateUserAgreement?: Maybe<Pick<UserAgreementMutation, 'isAgreementAccepted'>> };

export type PageAuthQueryVariables = Exact<{ [key: string]: never; }>;


export type PageAuthQuery = { currentUser?: Maybe<Pick<UserType, 'id'>> };

export type PageCheckersDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type PageCheckersDashboardQuery = { currentUser?: Maybe<Pick<UserType, 'isStaff'>> };

export type PageCoursePageQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type PageCoursePageQuery = { course?: Maybe<(
    Pick<CourseType, 'materials'>
    & PageCourseMainInfoFragment
    & PageCourseBreadCrumbsFragment
    & PageCourseSectionListFragment
    & FaqListFragment
  )> };

export type PageCourseBreadCrumbsFragment = Pick<CourseType, 'title'>;

export type PageCourseMainInfoFragment = { courseKind: Pick<CourseKindType, 'label'> };

export type PageCourseLectureListFragment = { lectures: Array<(
    Pick<CourseLectureType, 'id'>
    & PageCourseLectureItemFragment
  )> };

export type PageCourseLectureItemFragment = (
  Pick<CourseLectureType, 'id' | 'title' | 'description' | 'thumbnail'>
  & { contents: Array<Pick<CourseContentType, 'id'>> }
);

export type PageCourseSectionListFragment = { sections: Array<(
    Pick<CourseSectionType, 'id' | 'title' | 'description'>
    & PageCourseLectureListFragment
  )> };

export type PageLectureQueryVariables = Exact<{
  courseSlug: Scalars['String'];
  lectureId: Scalars['ID'];
  contentId: Scalars['ID'];
}>;


export type PageLectureQuery = { course?: Maybe<(
    { lecture?: Maybe<(
      Pick<CourseLectureType, 'title'>
      & { content?: Maybe<Pick<CourseContentType, 'id' | 'title' | 'body'>> }
      & PageLectureBreadCrumbsLectureFragment
      & PageLectureNavFragment
    )> }
    & PageLectureBreadCrumbsCourseFragment
  )> };

export type PageLectureBreadCrumbsCourseFragment = Pick<CourseType, 'slug' | 'title'>;

export type PageLectureBreadCrumbsLectureFragment = Pick<CourseLectureType, 'title'>;

export type PageLectureNavFragment = (
  Pick<CourseLectureType, 'id'>
  & { contents: Array<Pick<CourseContentType, 'id' | 'title'>> }
);

export type PageProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type PageProfileQuery = { currentUser?: Maybe<(
    Pick<UserType, 'username' | 'avatar' | 'isDiscordRequired'>
    & UserPhoneFormFragment
    & UserDiscordControlFragment
  )> };

export type PageStreamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PageStreamQuery = { stream?: Maybe<(
    Pick<LiveStreamType, 'body' | 'description' | 'startDate' | 'title' | 'url' | 'pk'>
    & { courses?: Maybe<Array<Pick<CourseType, 'slug' | 'title'>>>, access: Pick<LiveStreamAccess, 'view'> }
    & PageStreamBreadCrumbsFragment
  )> };

export type PageStreamBreadCrumbsFragment = Pick<LiveStreamType, 'title'>;

export type PageSubscriptionCurrentSubscriptionFragment = Pick<XyzPlusSubscriptionType, 'tariffTitle' | 'priceWithDiscount' | 'monthlyPrice' | 'nextTransactionDate' | 'prepaidDuration'>;

export type PageSubscriptionSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type PageSubscriptionSettingsQuery = { xyzPlus?: Maybe<(
    { currentSubscription?: Maybe<(
      Pick<XyzPlusSubscriptionType, 'status'>
      & PageSubscriptionCurrentSubscriptionFragment
    )> }
    & XyzPlusStatusInformerFragment
  )> };

export type PageSubscriptionTariffItemFragment = Pick<XyzPlusTariffType, 'kind' | 'title' | 'duration' | 'discount' | 'price' | 'priceWithDiscount' | 'monthlyPrice' | 'trial'>;

export type PageSubscriptionTariffsQueryVariables = Exact<{ [key: string]: never; }>;


export type PageSubscriptionTariffsQuery = { xyzPlus?: Maybe<{ tariffs: Array<PageSubscriptionTariffItemFragment> }> };

export type PageXyzPlusContentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PageXyzPlusContentQuery = { xyzPlusContent?: Maybe<(
    Pick<XyzPlusContentType, 'title'>
    & { access: Pick<XyzPlusContentAccess, 'view'> }
    & PageXyzPlusContentBreadCrumbsFragment
    & PageXyzPlusContentPreviewFragment
    & PageXyzPlusContentControlsFragment
    & PageXyzPlusContentBodyFragment
  )> };

export type PageXyzPlusContentBodyFragment = Pick<XyzPlusContentType, 'body'>;

export type PageXyzPlusContentBreadCrumbsFragment = Pick<XyzPlusContentType, 'title'>;

export type PageXyzPlusContentControlsFragment = { access: Pick<XyzPlusContentAccess, 'view'> };

export type PageXyzPlusContentPreviewFragment = (
  Pick<XyzPlusContentType, 'description' | 'thumbnail'>
  & { category?: Maybe<Pick<CategoryType, 'title'>>, relatedCourse?: Maybe<Pick<CourseType, 'title'>> }
);

export const AmoWidgetFragmentDoc = gql`
    fragment AmoWidget on UserType {
  id
  username
}
    `;
export const FaqItemFragmentDoc = gql`
    fragment FaqItem on QuestionType {
  text
  answer
}
    `;
export const FaqListFragmentDoc = gql`
    fragment FaqList on CourseType {
  questions {
    ...FaqItem
  }
}
    ${FaqItemFragmentDoc}`;
export const PageLayoutHeaderCurrentUserFragmentDoc = gql`
    fragment PageLayoutHeaderCurrentUser on UserType {
  username
  avatar
  isAgreementAccepted
  isStaff
  awaitingAssignment
}
    `;
export const BannerFragmentDoc = gql`
    fragment Banner on BannerType {
  url
  desktopImage
  mobileImage
}
    `;
export const CommonDiscordServerInformerFragmentDoc = gql`
    fragment CommonDiscordServerInformer on DiscordServerType {
  inviteUrl
}
    `;
export const StreamCardFragmentDoc = gql`
    fragment StreamCard on LiveStreamType {
  id
  title
  thumbnail
  courses {
    id
    title
  }
  description
  startDate
}
    `;
export const CurrentUserProviderFragmentDoc = gql`
    fragment CurrentUserProvider on Query {
  features
}
    `;
export const UserDiscordControlFragmentDoc = gql`
    fragment UserDiscordControl on UserType {
  username
  avatar
  isDiscordRequired
}
    `;
export const UserPhoneFormFragmentDoc = gql`
    fragment UserPhoneForm on UserType {
  phone
  contactPhone
}
    `;
export const XyzPlusContentCardFragmentDoc = gql`
    fragment XyzPlusContentCard on XyzPlusContentType {
  id
  title
  thumbnail
  relatedCourse {
    title
  }
  category {
    title
  }
  isNew
  status
}
    `;
export const XyzPlusStatusInformerFragmentDoc = gql`
    fragment XyzPlusStatusInformer on XYZPlusType {
  xyzPlusExpirationDate
  xyzPlusStatus
  currentSubscription {
    status
  }
}
    `;
export const CourseCardControlsFragmentDoc = gql`
    fragment CourseCardControls on CourseType {
  slug
  isFree
  infoUrl
  purchaseUrl
  access {
    view
  }
}
    `;
export const CourseCardPriceFragmentDoc = gql`
    fragment CourseCardPrice on CourseType {
  price
  oldPrice
  isFree
}
    `;
export const CourseCardPromoFragmentDoc = gql`
    fragment CourseCardPromo on CourseType {
  id
  slug
  isFree
  thumbnail
  title
  courseKind {
    label
  }
  infoUrl
  purchaseUrl
  access {
    view
  }
  ...CourseCardControls
  ...CourseCardPrice
}
    ${CourseCardControlsFragmentDoc}
${CourseCardPriceFragmentDoc}`;
export const DashboardCoursesListFragmentDoc = gql`
    fragment DashboardCoursesList on CourseTypeConnection {
  edges {
    node {
      id
      ...CourseCardPromo
    }
  }
}
    ${CourseCardPromoFragmentDoc}`;
export const EnrollmentStatusFragmentDoc = gql`
    fragment EnrollmentStatus on EnrollmentType {
  id
  startDate
  endDate
  state
}
    `;
export const EnrollmentDateFragmentDoc = gql`
    fragment EnrollmentDate on EnrollmentType {
  startDate
  endDate
}
    `;
export const EnrollmentProgressFragmentDoc = gql`
    fragment EnrollmentProgress on EnrollmentType {
  course {
    slug
  }
  progress {
    completion
    expectedProgress
    completedHomeworks
    totalHomeworks
  }
  state
  canBuyHomework
}
    `;
export const EnrollmentCardEnrolledFragmentDoc = gql`
    fragment EnrollmentCardEnrolled on EnrollmentType {
  id
  startDate
  ...EnrollmentStatus
  ...EnrollmentDate
  ...EnrollmentProgress
  course {
    slug
    courseKind {
      label
    }
    thumbnail
    title
    isFree
  }
}
    ${EnrollmentStatusFragmentDoc}
${EnrollmentDateFragmentDoc}
${EnrollmentProgressFragmentDoc}`;
export const EnrollmentBundleCourseCardFragmentDoc = gql`
    fragment EnrollmentBundleCourseCard on CourseType {
  courseKind {
    label
  }
  thumbnail
  title
}
    `;
export const EnrollmentBundleCardFragmentDoc = gql`
    fragment EnrollmentBundleCard on EnrollmentType {
  course {
    title
    courseKind {
      label
    }
    courses {
      id
      isFree
      ...EnrollmentBundleCourseCard
    }
  }
  enrollments {
    startDate
    endDate
    course {
      id
      isFree
    }
    ...EnrollmentCardEnrolled
  }
  nextCourse {
    course {
      id
    }
    startDate
  }
}
    ${EnrollmentBundleCourseCardFragmentDoc}
${EnrollmentCardEnrolledFragmentDoc}`;
export const EnrollmentCardFragmentDoc = gql`
    fragment EnrollmentCard on EnrollmentType {
  course {
    contentKind
  }
  ...EnrollmentCardEnrolled
  ...EnrollmentBundleCard
}
    ${EnrollmentCardEnrolledFragmentDoc}
${EnrollmentBundleCardFragmentDoc}`;
export const DashboardEnrollmentsListFragmentDoc = gql`
    fragment DashboardEnrollmentsList on EnrollmentTypeConnection {
  edges {
    node {
      ...EnrollmentCard
    }
  }
}
    ${EnrollmentCardFragmentDoc}`;
export const DashboardXyzPlusFragmentDoc = gql`
    fragment DashboardXyzPlus on Query {
  xyzPlus {
    isXyzPlusActive
  }
}
    `;
export const PageCourseBreadCrumbsFragmentDoc = gql`
    fragment PageCourseBreadCrumbs on CourseType {
  title
}
    `;
export const PageCourseMainInfoFragmentDoc = gql`
    fragment PageCourseMainInfo on CourseType {
  courseKind {
    label
  }
}
    `;
export const PageCourseLectureItemFragmentDoc = gql`
    fragment PageCourseLectureItem on CourseLectureType {
  id
  title
  description
  thumbnail
  contents {
    id
  }
}
    `;
export const PageCourseLectureListFragmentDoc = gql`
    fragment PageCourseLectureList on CourseSectionType {
  lectures {
    id
    ...PageCourseLectureItem
  }
}
    ${PageCourseLectureItemFragmentDoc}`;
export const PageCourseSectionListFragmentDoc = gql`
    fragment PageCourseSectionList on CourseType {
  sections {
    id
    title
    description
    ...PageCourseLectureList
  }
}
    ${PageCourseLectureListFragmentDoc}`;
export const PageLectureBreadCrumbsCourseFragmentDoc = gql`
    fragment PageLectureBreadCrumbsCourse on CourseType {
  slug
  title
}
    `;
export const PageLectureBreadCrumbsLectureFragmentDoc = gql`
    fragment PageLectureBreadCrumbsLecture on CourseLectureType {
  title
}
    `;
export const PageLectureNavFragmentDoc = gql`
    fragment PageLectureNav on CourseLectureType {
  id
  contents {
    id
    title
  }
}
    `;
export const PageStreamBreadCrumbsFragmentDoc = gql`
    fragment PageStreamBreadCrumbs on LiveStreamType {
  title
}
    `;
export const PageSubscriptionCurrentSubscriptionFragmentDoc = gql`
    fragment PageSubscriptionCurrentSubscription on XyzPlusSubscriptionType {
  tariffTitle
  priceWithDiscount
  monthlyPrice
  priceWithDiscount
  nextTransactionDate
  prepaidDuration
}
    `;
export const PageSubscriptionTariffItemFragmentDoc = gql`
    fragment PageSubscriptionTariffItem on XyzPlusTariffType {
  kind
  title
  duration
  discount
  price
  priceWithDiscount
  monthlyPrice
  trial
}
    `;
export const PageXyzPlusContentBodyFragmentDoc = gql`
    fragment PageXyzPlusContentBody on XyzPlusContentType {
  body
}
    `;
export const PageXyzPlusContentBreadCrumbsFragmentDoc = gql`
    fragment PageXyzPlusContentBreadCrumbs on XyzPlusContentType {
  title
}
    `;
export const PageXyzPlusContentControlsFragmentDoc = gql`
    fragment PageXyzPlusContentControls on XyzPlusContentType {
  access {
    view
  }
}
    `;
export const PageXyzPlusContentPreviewFragmentDoc = gql`
    fragment PageXyzPlusContentPreview on XyzPlusContentType {
  description
  thumbnail
  category {
    title
  }
  relatedCourse {
    title
  }
}
    `;
export const RequestAuthDocument = gql`
    mutation RequestAuth($input: RequestAuthDTO!) {
  requestAuth(input: $input) {
    success
  }
}
    `;
export type RequestAuthMutationFn = Apollo.MutationFunction<RequestAuthMutation, RequestAuthMutationVariables>;

/**
 * __useRequestAuthMutation__
 *
 * To run a mutation, you first call `useRequestAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAuthMutation, { data, loading, error }] = useRequestAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestAuthMutation(baseOptions?: Apollo.MutationHookOptions<RequestAuthMutation, RequestAuthMutationVariables>) {
        return Apollo.useMutation<RequestAuthMutation, RequestAuthMutationVariables>(RequestAuthDocument, baseOptions);
      }
export type RequestAuthMutationHookResult = ReturnType<typeof useRequestAuthMutation>;
export type RequestAuthMutationResult = Apollo.MutationResult<RequestAuthMutation>;
export type RequestAuthMutationOptions = Apollo.BaseMutationOptions<RequestAuthMutation, RequestAuthMutationVariables>;
export const PhoneVerificationDocument = gql`
    mutation PhoneVerification($input: PhoneVerificationDTO!) {
  phoneVerification(input: $input) {
    verificationId
    expiredAt
  }
}
    `;
export type PhoneVerificationMutationFn = Apollo.MutationFunction<PhoneVerificationMutation, PhoneVerificationMutationVariables>;

/**
 * __usePhoneVerificationMutation__
 *
 * To run a mutation, you first call `usePhoneVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneVerificationMutation, { data, loading, error }] = usePhoneVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePhoneVerificationMutation(baseOptions?: Apollo.MutationHookOptions<PhoneVerificationMutation, PhoneVerificationMutationVariables>) {
        return Apollo.useMutation<PhoneVerificationMutation, PhoneVerificationMutationVariables>(PhoneVerificationDocument, baseOptions);
      }
export type PhoneVerificationMutationHookResult = ReturnType<typeof usePhoneVerificationMutation>;
export type PhoneVerificationMutationResult = Apollo.MutationResult<PhoneVerificationMutation>;
export type PhoneVerificationMutationOptions = Apollo.BaseMutationOptions<PhoneVerificationMutation, PhoneVerificationMutationVariables>;
export const PageLayoutDocument = gql`
    query PageLayout {
  ...CurrentUserProvider
  currentUser {
    ...AmoWidget
    ...PageLayoutHeaderCurrentUser
    isAgreementAccepted
  }
}
    ${CurrentUserProviderFragmentDoc}
${AmoWidgetFragmentDoc}
${PageLayoutHeaderCurrentUserFragmentDoc}`;

/**
 * __usePageLayoutQuery__
 *
 * To run a query within a React component, call `usePageLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageLayoutQuery(baseOptions?: Apollo.QueryHookOptions<PageLayoutQuery, PageLayoutQueryVariables>) {
        return Apollo.useQuery<PageLayoutQuery, PageLayoutQueryVariables>(PageLayoutDocument, baseOptions);
      }
export function usePageLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageLayoutQuery, PageLayoutQueryVariables>) {
          return Apollo.useLazyQuery<PageLayoutQuery, PageLayoutQueryVariables>(PageLayoutDocument, baseOptions);
        }
export type PageLayoutQueryHookResult = ReturnType<typeof usePageLayoutQuery>;
export type PageLayoutLazyQueryHookResult = ReturnType<typeof usePageLayoutLazyQuery>;
export type PageLayoutQueryResult = Apollo.QueryResult<PageLayoutQuery, PageLayoutQueryVariables>;
export const StaffEditBtnDocument = gql`
    query StaffEditBtn {
  currentUser {
    isStaff
  }
}
    `;

/**
 * __useStaffEditBtnQuery__
 *
 * To run a query within a React component, call `useStaffEditBtnQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffEditBtnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffEditBtnQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffEditBtnQuery(baseOptions?: Apollo.QueryHookOptions<StaffEditBtnQuery, StaffEditBtnQueryVariables>) {
        return Apollo.useQuery<StaffEditBtnQuery, StaffEditBtnQueryVariables>(StaffEditBtnDocument, baseOptions);
      }
export function useStaffEditBtnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffEditBtnQuery, StaffEditBtnQueryVariables>) {
          return Apollo.useLazyQuery<StaffEditBtnQuery, StaffEditBtnQueryVariables>(StaffEditBtnDocument, baseOptions);
        }
export type StaffEditBtnQueryHookResult = ReturnType<typeof useStaffEditBtnQuery>;
export type StaffEditBtnLazyQueryHookResult = ReturnType<typeof useStaffEditBtnLazyQuery>;
export type StaffEditBtnQueryResult = Apollo.QueryResult<StaffEditBtnQuery, StaffEditBtnQueryVariables>;
export const CheckerAssignmentsListDocument = gql`
    query checkerAssignmentsList($after: String) {
  assignments(after: $after) {
    edges {
      node {
        id
        awaitingCheck
        dateSubmitted
        dateChecked
        course {
          title
        }
        user {
          avatar
          username
        }
        lecture {
          title
        }
        content {
          title
        }
      }
    }
  }
}
    `;

/**
 * __useCheckerAssignmentsListQuery__
 *
 * To run a query within a React component, call `useCheckerAssignmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckerAssignmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckerAssignmentsListQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCheckerAssignmentsListQuery(baseOptions?: Apollo.QueryHookOptions<CheckerAssignmentsListQuery, CheckerAssignmentsListQueryVariables>) {
        return Apollo.useQuery<CheckerAssignmentsListQuery, CheckerAssignmentsListQueryVariables>(CheckerAssignmentsListDocument, baseOptions);
      }
export function useCheckerAssignmentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckerAssignmentsListQuery, CheckerAssignmentsListQueryVariables>) {
          return Apollo.useLazyQuery<CheckerAssignmentsListQuery, CheckerAssignmentsListQueryVariables>(CheckerAssignmentsListDocument, baseOptions);
        }
export type CheckerAssignmentsListQueryHookResult = ReturnType<typeof useCheckerAssignmentsListQuery>;
export type CheckerAssignmentsListLazyQueryHookResult = ReturnType<typeof useCheckerAssignmentsListLazyQuery>;
export type CheckerAssignmentsListQueryResult = Apollo.QueryResult<CheckerAssignmentsListQuery, CheckerAssignmentsListQueryVariables>;
export const UpdateLectureDocument = gql`
    mutation UpdateLecture($input: CourseLectureDTO!) {
  updateLecture(input: $input) {
    lecture {
      id
      contents {
        id
      }
    }
  }
}
    `;
export type UpdateLectureMutationFn = Apollo.MutationFunction<UpdateLectureMutation, UpdateLectureMutationVariables>;

/**
 * __useUpdateLectureMutation__
 *
 * To run a mutation, you first call `useUpdateLectureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLectureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLectureMutation, { data, loading, error }] = useUpdateLectureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLectureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLectureMutation, UpdateLectureMutationVariables>) {
        return Apollo.useMutation<UpdateLectureMutation, UpdateLectureMutationVariables>(UpdateLectureDocument, baseOptions);
      }
export type UpdateLectureMutationHookResult = ReturnType<typeof useUpdateLectureMutation>;
export type UpdateLectureMutationResult = Apollo.MutationResult<UpdateLectureMutation>;
export type UpdateLectureMutationOptions = Apollo.BaseMutationOptions<UpdateLectureMutation, UpdateLectureMutationVariables>;
export const UserIdDocument = gql`
    query UserId {
  currentUser {
    id
  }
}
    `;

/**
 * __useUserIdQuery__
 *
 * To run a query within a React component, call `useUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIdQuery(baseOptions?: Apollo.QueryHookOptions<UserIdQuery, UserIdQueryVariables>) {
        return Apollo.useQuery<UserIdQuery, UserIdQueryVariables>(UserIdDocument, baseOptions);
      }
export function useUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIdQuery, UserIdQueryVariables>) {
          return Apollo.useLazyQuery<UserIdQuery, UserIdQueryVariables>(UserIdDocument, baseOptions);
        }
export type UserIdQueryHookResult = ReturnType<typeof useUserIdQuery>;
export type UserIdLazyQueryHookResult = ReturnType<typeof useUserIdLazyQuery>;
export type UserIdQueryResult = Apollo.QueryResult<UserIdQuery, UserIdQueryVariables>;
export const StreamsDocument = gql`
    query streams($after: String) {
  streams(after: $after) {
    edges {
      node {
        id
        startDate
        access {
          view
        }
        ...StreamCard
      }
    }
  }
}
    ${StreamCardFragmentDoc}`;

/**
 * __useStreamsQuery__
 *
 * To run a query within a React component, call `useStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamsQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useStreamsQuery(baseOptions?: Apollo.QueryHookOptions<StreamsQuery, StreamsQueryVariables>) {
        return Apollo.useQuery<StreamsQuery, StreamsQueryVariables>(StreamsDocument, baseOptions);
      }
export function useStreamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StreamsQuery, StreamsQueryVariables>) {
          return Apollo.useLazyQuery<StreamsQuery, StreamsQueryVariables>(StreamsDocument, baseOptions);
        }
export type StreamsQueryHookResult = ReturnType<typeof useStreamsQuery>;
export type StreamsLazyQueryHookResult = ReturnType<typeof useStreamsLazyQuery>;
export type StreamsQueryResult = Apollo.QueryResult<StreamsQuery, StreamsQueryVariables>;
export const ContactInfoDocument = gql`
    query ContactInfo {
  currentUser {
    firstName
    lastName
    middleName
    contactPhone
    contactEmail
    gender
  }
}
    `;

/**
 * __useContactInfoQuery__
 *
 * To run a query within a React component, call `useContactInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactInfoQuery(baseOptions?: Apollo.QueryHookOptions<ContactInfoQuery, ContactInfoQueryVariables>) {
        return Apollo.useQuery<ContactInfoQuery, ContactInfoQueryVariables>(ContactInfoDocument, baseOptions);
      }
export function useContactInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactInfoQuery, ContactInfoQueryVariables>) {
          return Apollo.useLazyQuery<ContactInfoQuery, ContactInfoQueryVariables>(ContactInfoDocument, baseOptions);
        }
export type ContactInfoQueryHookResult = ReturnType<typeof useContactInfoQuery>;
export type ContactInfoLazyQueryHookResult = ReturnType<typeof useContactInfoLazyQuery>;
export type ContactInfoQueryResult = Apollo.QueryResult<ContactInfoQuery, ContactInfoQueryVariables>;
export const UpdateUserContactInfoDocument = gql`
    mutation UpdateUserContactInfo($input: UserContactInfoDTO) {
  updateUserContactInfo(input: $input) {
    userContactInfo {
      firstName
      lastName
      middleName
      contactEmail
      gender
    }
  }
}
    `;
export type UpdateUserContactInfoMutationFn = Apollo.MutationFunction<UpdateUserContactInfoMutation, UpdateUserContactInfoMutationVariables>;

/**
 * __useUpdateUserContactInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserContactInfoMutation, { data, loading, error }] = useUpdateUserContactInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserContactInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserContactInfoMutation, UpdateUserContactInfoMutationVariables>) {
        return Apollo.useMutation<UpdateUserContactInfoMutation, UpdateUserContactInfoMutationVariables>(UpdateUserContactInfoDocument, baseOptions);
      }
export type UpdateUserContactInfoMutationHookResult = ReturnType<typeof useUpdateUserContactInfoMutation>;
export type UpdateUserContactInfoMutationResult = Apollo.MutationResult<UpdateUserContactInfoMutation>;
export type UpdateUserContactInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserContactInfoMutation, UpdateUserContactInfoMutationVariables>;
export const ProfilePhoneVerificationDocument = gql`
    mutation ProfilePhoneVerification($input: ProfilePhoneVerificationDTO!) {
  profilePhoneVerification(input: $input) {
    verificationId
    expiredAt
  }
}
    `;
export type ProfilePhoneVerificationMutationFn = Apollo.MutationFunction<ProfilePhoneVerificationMutation, ProfilePhoneVerificationMutationVariables>;

/**
 * __useProfilePhoneVerificationMutation__
 *
 * To run a mutation, you first call `useProfilePhoneVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfilePhoneVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profilePhoneVerificationMutation, { data, loading, error }] = useProfilePhoneVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfilePhoneVerificationMutation(baseOptions?: Apollo.MutationHookOptions<ProfilePhoneVerificationMutation, ProfilePhoneVerificationMutationVariables>) {
        return Apollo.useMutation<ProfilePhoneVerificationMutation, ProfilePhoneVerificationMutationVariables>(ProfilePhoneVerificationDocument, baseOptions);
      }
export type ProfilePhoneVerificationMutationHookResult = ReturnType<typeof useProfilePhoneVerificationMutation>;
export type ProfilePhoneVerificationMutationResult = Apollo.MutationResult<ProfilePhoneVerificationMutation>;
export type ProfilePhoneVerificationMutationOptions = Apollo.BaseMutationOptions<ProfilePhoneVerificationMutation, ProfilePhoneVerificationMutationVariables>;
export const XyzPlusContentsCategoriesDocument = gql`
    query xyzPlusContentsCategories {
  xyzPlus {
    categories {
      id
      title
    }
    courses {
      id
      title
    }
  }
}
    `;

/**
 * __useXyzPlusContentsCategoriesQuery__
 *
 * To run a query within a React component, call `useXyzPlusContentsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useXyzPlusContentsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useXyzPlusContentsCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useXyzPlusContentsCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<XyzPlusContentsCategoriesQuery, XyzPlusContentsCategoriesQueryVariables>) {
        return Apollo.useQuery<XyzPlusContentsCategoriesQuery, XyzPlusContentsCategoriesQueryVariables>(XyzPlusContentsCategoriesDocument, baseOptions);
      }
export function useXyzPlusContentsCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<XyzPlusContentsCategoriesQuery, XyzPlusContentsCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<XyzPlusContentsCategoriesQuery, XyzPlusContentsCategoriesQueryVariables>(XyzPlusContentsCategoriesDocument, baseOptions);
        }
export type XyzPlusContentsCategoriesQueryHookResult = ReturnType<typeof useXyzPlusContentsCategoriesQuery>;
export type XyzPlusContentsCategoriesLazyQueryHookResult = ReturnType<typeof useXyzPlusContentsCategoriesLazyQuery>;
export type XyzPlusContentsCategoriesQueryResult = Apollo.QueryResult<XyzPlusContentsCategoriesQuery, XyzPlusContentsCategoriesQueryVariables>;
export const XyzPlusContentsDocument = gql`
    query xyzPlusContents($category: ID, $relatedCourse: ID, $title_Icontains: String, $after: String) {
  xyzPlusContents(
    category: $category
    relatedCourse: $relatedCourse
    title_Icontains: $title_Icontains
    after: $after
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        ...XyzPlusContentCard
      }
      cursor
    }
  }
}
    ${XyzPlusContentCardFragmentDoc}`;

/**
 * __useXyzPlusContentsQuery__
 *
 * To run a query within a React component, call `useXyzPlusContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useXyzPlusContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useXyzPlusContentsQuery({
 *   variables: {
 *      category: // value for 'category'
 *      relatedCourse: // value for 'relatedCourse'
 *      title_Icontains: // value for 'title_Icontains'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useXyzPlusContentsQuery(baseOptions?: Apollo.QueryHookOptions<XyzPlusContentsQuery, XyzPlusContentsQueryVariables>) {
        return Apollo.useQuery<XyzPlusContentsQuery, XyzPlusContentsQueryVariables>(XyzPlusContentsDocument, baseOptions);
      }
export function useXyzPlusContentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<XyzPlusContentsQuery, XyzPlusContentsQueryVariables>) {
          return Apollo.useLazyQuery<XyzPlusContentsQuery, XyzPlusContentsQueryVariables>(XyzPlusContentsDocument, baseOptions);
        }
export type XyzPlusContentsQueryHookResult = ReturnType<typeof useXyzPlusContentsQuery>;
export type XyzPlusContentsLazyQueryHookResult = ReturnType<typeof useXyzPlusContentsLazyQuery>;
export type XyzPlusContentsQueryResult = Apollo.QueryResult<XyzPlusContentsQuery, XyzPlusContentsQueryVariables>;
export const CreateXyzPlusSubscriptionInvoiceDocument = gql`
    mutation createXyzPlusSubscriptionInvoice($input: TariffDTO!) {
  createXyzPlusSubscriptionInvoice(input: $input) {
    xyzPlusSubscriptionInvoice {
      cpWidgetData
    }
  }
}
    `;
export type CreateXyzPlusSubscriptionInvoiceMutationFn = Apollo.MutationFunction<CreateXyzPlusSubscriptionInvoiceMutation, CreateXyzPlusSubscriptionInvoiceMutationVariables>;

/**
 * __useCreateXyzPlusSubscriptionInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateXyzPlusSubscriptionInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateXyzPlusSubscriptionInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createXyzPlusSubscriptionInvoiceMutation, { data, loading, error }] = useCreateXyzPlusSubscriptionInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateXyzPlusSubscriptionInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateXyzPlusSubscriptionInvoiceMutation, CreateXyzPlusSubscriptionInvoiceMutationVariables>) {
        return Apollo.useMutation<CreateXyzPlusSubscriptionInvoiceMutation, CreateXyzPlusSubscriptionInvoiceMutationVariables>(CreateXyzPlusSubscriptionInvoiceDocument, baseOptions);
      }
export type CreateXyzPlusSubscriptionInvoiceMutationHookResult = ReturnType<typeof useCreateXyzPlusSubscriptionInvoiceMutation>;
export type CreateXyzPlusSubscriptionInvoiceMutationResult = Apollo.MutationResult<CreateXyzPlusSubscriptionInvoiceMutation>;
export type CreateXyzPlusSubscriptionInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateXyzPlusSubscriptionInvoiceMutation, CreateXyzPlusSubscriptionInvoiceMutationVariables>;
export const IsXyzPlusActiveDocument = gql`
    query IsXyzPlusActive {
  xyzPlus {
    isXyzPlusActive
  }
}
    `;

/**
 * __useIsXyzPlusActiveQuery__
 *
 * To run a query within a React component, call `useIsXyzPlusActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsXyzPlusActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsXyzPlusActiveQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsXyzPlusActiveQuery(baseOptions?: Apollo.QueryHookOptions<IsXyzPlusActiveQuery, IsXyzPlusActiveQueryVariables>) {
        return Apollo.useQuery<IsXyzPlusActiveQuery, IsXyzPlusActiveQueryVariables>(IsXyzPlusActiveDocument, baseOptions);
      }
export function useIsXyzPlusActiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsXyzPlusActiveQuery, IsXyzPlusActiveQueryVariables>) {
          return Apollo.useLazyQuery<IsXyzPlusActiveQuery, IsXyzPlusActiveQueryVariables>(IsXyzPlusActiveDocument, baseOptions);
        }
export type IsXyzPlusActiveQueryHookResult = ReturnType<typeof useIsXyzPlusActiveQuery>;
export type IsXyzPlusActiveLazyQueryHookResult = ReturnType<typeof useIsXyzPlusActiveLazyQuery>;
export type IsXyzPlusActiveQueryResult = Apollo.QueryResult<IsXyzPlusActiveQuery, IsXyzPlusActiveQueryVariables>;
export const DashboardDocument = gql`
    query Dashboard {
  banner: banner(position: DASHBOARD) {
    ...Banner
  }
  ...DashboardXyzPlus
}
    ${BannerFragmentDoc}
${DashboardXyzPlusFragmentDoc}`;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const DashboardAllDocument = gql`
    query DashboardAll {
  draftCourses: courses(scope: "draft") {
    ...DashboardCoursesList
  }
  availableCourses: courses(scope: "available") {
    ...DashboardCoursesList
  }
  archiveCourses: courses(scope: "archive") {
    ...DashboardCoursesList
  }
}
    ${DashboardCoursesListFragmentDoc}`;

/**
 * __useDashboardAllQuery__
 *
 * To run a query within a React component, call `useDashboardAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardAllQuery(baseOptions?: Apollo.QueryHookOptions<DashboardAllQuery, DashboardAllQueryVariables>) {
        return Apollo.useQuery<DashboardAllQuery, DashboardAllQueryVariables>(DashboardAllDocument, baseOptions);
      }
export function useDashboardAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardAllQuery, DashboardAllQueryVariables>) {
          return Apollo.useLazyQuery<DashboardAllQuery, DashboardAllQueryVariables>(DashboardAllDocument, baseOptions);
        }
export type DashboardAllQueryHookResult = ReturnType<typeof useDashboardAllQuery>;
export type DashboardAllLazyQueryHookResult = ReturnType<typeof useDashboardAllLazyQuery>;
export type DashboardAllQueryResult = Apollo.QueryResult<DashboardAllQuery, DashboardAllQueryVariables>;
export const DashboardMyDocument = gql`
    query DashboardMy {
  enrollments {
    ...DashboardEnrollmentsList
  }
  currentUser {
    isDiscordRequired
  }
  recomendedCourses: courses(scope: "recommendation") {
    ...DashboardCoursesList
  }
  staffCourses: courses(scope: "staff") {
    ...DashboardCoursesList
  }
  reviewCourses: courses(scope: "checker") {
    ...DashboardCoursesList
  }
}
    ${DashboardEnrollmentsListFragmentDoc}
${DashboardCoursesListFragmentDoc}`;

/**
 * __useDashboardMyQuery__
 *
 * To run a query within a React component, call `useDashboardMyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardMyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardMyQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardMyQuery(baseOptions?: Apollo.QueryHookOptions<DashboardMyQuery, DashboardMyQueryVariables>) {
        return Apollo.useQuery<DashboardMyQuery, DashboardMyQueryVariables>(DashboardMyDocument, baseOptions);
      }
export function useDashboardMyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardMyQuery, DashboardMyQueryVariables>) {
          return Apollo.useLazyQuery<DashboardMyQuery, DashboardMyQueryVariables>(DashboardMyDocument, baseOptions);
        }
export type DashboardMyQueryHookResult = ReturnType<typeof useDashboardMyQuery>;
export type DashboardMyLazyQueryHookResult = ReturnType<typeof useDashboardMyLazyQuery>;
export type DashboardMyQueryResult = Apollo.QueryResult<DashboardMyQuery, DashboardMyQueryVariables>;
export const PageAgreementDocument = gql`
    query PageAgreement {
  currentUser {
    agreementUpdates {
      id
      body
      updateDate
    }
  }
}
    `;

/**
 * __usePageAgreementQuery__
 *
 * To run a query within a React component, call `usePageAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageAgreementQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageAgreementQuery(baseOptions?: Apollo.QueryHookOptions<PageAgreementQuery, PageAgreementQueryVariables>) {
        return Apollo.useQuery<PageAgreementQuery, PageAgreementQueryVariables>(PageAgreementDocument, baseOptions);
      }
export function usePageAgreementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageAgreementQuery, PageAgreementQueryVariables>) {
          return Apollo.useLazyQuery<PageAgreementQuery, PageAgreementQueryVariables>(PageAgreementDocument, baseOptions);
        }
export type PageAgreementQueryHookResult = ReturnType<typeof usePageAgreementQuery>;
export type PageAgreementLazyQueryHookResult = ReturnType<typeof usePageAgreementLazyQuery>;
export type PageAgreementQueryResult = Apollo.QueryResult<PageAgreementQuery, PageAgreementQueryVariables>;
export const UpdateUserAgreementDocument = gql`
    mutation updateUserAgreement($input: AcceptAgreementDTO!) {
  updateUserAgreement(input: $input) {
    isAgreementAccepted
  }
}
    `;
export type UpdateUserAgreementMutationFn = Apollo.MutationFunction<UpdateUserAgreementMutation, UpdateUserAgreementMutationVariables>;

/**
 * __useUpdateUserAgreementMutation__
 *
 * To run a mutation, you first call `useUpdateUserAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAgreementMutation, { data, loading, error }] = useUpdateUserAgreementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAgreementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAgreementMutation, UpdateUserAgreementMutationVariables>) {
        return Apollo.useMutation<UpdateUserAgreementMutation, UpdateUserAgreementMutationVariables>(UpdateUserAgreementDocument, baseOptions);
      }
export type UpdateUserAgreementMutationHookResult = ReturnType<typeof useUpdateUserAgreementMutation>;
export type UpdateUserAgreementMutationResult = Apollo.MutationResult<UpdateUserAgreementMutation>;
export type UpdateUserAgreementMutationOptions = Apollo.BaseMutationOptions<UpdateUserAgreementMutation, UpdateUserAgreementMutationVariables>;
export const PageAuthDocument = gql`
    query PageAuth {
  currentUser {
    id
  }
}
    `;

/**
 * __usePageAuthQuery__
 *
 * To run a query within a React component, call `usePageAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageAuthQuery(baseOptions?: Apollo.QueryHookOptions<PageAuthQuery, PageAuthQueryVariables>) {
        return Apollo.useQuery<PageAuthQuery, PageAuthQueryVariables>(PageAuthDocument, baseOptions);
      }
export function usePageAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageAuthQuery, PageAuthQueryVariables>) {
          return Apollo.useLazyQuery<PageAuthQuery, PageAuthQueryVariables>(PageAuthDocument, baseOptions);
        }
export type PageAuthQueryHookResult = ReturnType<typeof usePageAuthQuery>;
export type PageAuthLazyQueryHookResult = ReturnType<typeof usePageAuthLazyQuery>;
export type PageAuthQueryResult = Apollo.QueryResult<PageAuthQuery, PageAuthQueryVariables>;
export const PageCheckersDashboardDocument = gql`
    query PageCheckersDashboard {
  currentUser {
    isStaff
  }
}
    `;

/**
 * __usePageCheckersDashboardQuery__
 *
 * To run a query within a React component, call `usePageCheckersDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageCheckersDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageCheckersDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageCheckersDashboardQuery(baseOptions?: Apollo.QueryHookOptions<PageCheckersDashboardQuery, PageCheckersDashboardQueryVariables>) {
        return Apollo.useQuery<PageCheckersDashboardQuery, PageCheckersDashboardQueryVariables>(PageCheckersDashboardDocument, baseOptions);
      }
export function usePageCheckersDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageCheckersDashboardQuery, PageCheckersDashboardQueryVariables>) {
          return Apollo.useLazyQuery<PageCheckersDashboardQuery, PageCheckersDashboardQueryVariables>(PageCheckersDashboardDocument, baseOptions);
        }
export type PageCheckersDashboardQueryHookResult = ReturnType<typeof usePageCheckersDashboardQuery>;
export type PageCheckersDashboardLazyQueryHookResult = ReturnType<typeof usePageCheckersDashboardLazyQuery>;
export type PageCheckersDashboardQueryResult = Apollo.QueryResult<PageCheckersDashboardQuery, PageCheckersDashboardQueryVariables>;
export const PageCoursePageDocument = gql`
    query PageCoursePage($slug: String!) {
  course(slug: $slug) {
    ...PageCourseMainInfo
    ...PageCourseBreadCrumbs
    ...PageCourseSectionList
    ...FaqList
    materials
  }
}
    ${PageCourseMainInfoFragmentDoc}
${PageCourseBreadCrumbsFragmentDoc}
${PageCourseSectionListFragmentDoc}
${FaqListFragmentDoc}`;

/**
 * __usePageCoursePageQuery__
 *
 * To run a query within a React component, call `usePageCoursePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageCoursePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageCoursePageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePageCoursePageQuery(baseOptions?: Apollo.QueryHookOptions<PageCoursePageQuery, PageCoursePageQueryVariables>) {
        return Apollo.useQuery<PageCoursePageQuery, PageCoursePageQueryVariables>(PageCoursePageDocument, baseOptions);
      }
export function usePageCoursePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageCoursePageQuery, PageCoursePageQueryVariables>) {
          return Apollo.useLazyQuery<PageCoursePageQuery, PageCoursePageQueryVariables>(PageCoursePageDocument, baseOptions);
        }
export type PageCoursePageQueryHookResult = ReturnType<typeof usePageCoursePageQuery>;
export type PageCoursePageLazyQueryHookResult = ReturnType<typeof usePageCoursePageLazyQuery>;
export type PageCoursePageQueryResult = Apollo.QueryResult<PageCoursePageQuery, PageCoursePageQueryVariables>;
export const PageLectureDocument = gql`
    query PageLecture($courseSlug: String!, $lectureId: ID!, $contentId: ID!) {
  course(slug: $courseSlug) {
    ...PageLectureBreadCrumbsCourse
    lecture(lectureId: $lectureId) {
      title
      ...PageLectureBreadCrumbsLecture
      ...PageLectureNav
      content(contentId: $contentId) {
        id
        title
        body
      }
    }
  }
}
    ${PageLectureBreadCrumbsCourseFragmentDoc}
${PageLectureBreadCrumbsLectureFragmentDoc}
${PageLectureNavFragmentDoc}`;

/**
 * __usePageLectureQuery__
 *
 * To run a query within a React component, call `usePageLectureQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageLectureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageLectureQuery({
 *   variables: {
 *      courseSlug: // value for 'courseSlug'
 *      lectureId: // value for 'lectureId'
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function usePageLectureQuery(baseOptions?: Apollo.QueryHookOptions<PageLectureQuery, PageLectureQueryVariables>) {
        return Apollo.useQuery<PageLectureQuery, PageLectureQueryVariables>(PageLectureDocument, baseOptions);
      }
export function usePageLectureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageLectureQuery, PageLectureQueryVariables>) {
          return Apollo.useLazyQuery<PageLectureQuery, PageLectureQueryVariables>(PageLectureDocument, baseOptions);
        }
export type PageLectureQueryHookResult = ReturnType<typeof usePageLectureQuery>;
export type PageLectureLazyQueryHookResult = ReturnType<typeof usePageLectureLazyQuery>;
export type PageLectureQueryResult = Apollo.QueryResult<PageLectureQuery, PageLectureQueryVariables>;
export const PageProfileDocument = gql`
    query PageProfile {
  currentUser {
    username
    avatar
    isDiscordRequired
    ...UserPhoneForm
    ...UserDiscordControl
  }
}
    ${UserPhoneFormFragmentDoc}
${UserDiscordControlFragmentDoc}`;

/**
 * __usePageProfileQuery__
 *
 * To run a query within a React component, call `usePageProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageProfileQuery(baseOptions?: Apollo.QueryHookOptions<PageProfileQuery, PageProfileQueryVariables>) {
        return Apollo.useQuery<PageProfileQuery, PageProfileQueryVariables>(PageProfileDocument, baseOptions);
      }
export function usePageProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageProfileQuery, PageProfileQueryVariables>) {
          return Apollo.useLazyQuery<PageProfileQuery, PageProfileQueryVariables>(PageProfileDocument, baseOptions);
        }
export type PageProfileQueryHookResult = ReturnType<typeof usePageProfileQuery>;
export type PageProfileLazyQueryHookResult = ReturnType<typeof usePageProfileLazyQuery>;
export type PageProfileQueryResult = Apollo.QueryResult<PageProfileQuery, PageProfileQueryVariables>;
export const PageStreamDocument = gql`
    query PageStream($id: ID!) {
  stream(id: $id) {
    body
    courses {
      slug
      title
    }
    access {
      view
    }
    description
    startDate
    title
    url
    pk
    ...PageStreamBreadCrumbs
  }
}
    ${PageStreamBreadCrumbsFragmentDoc}`;

/**
 * __usePageStreamQuery__
 *
 * To run a query within a React component, call `usePageStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageStreamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePageStreamQuery(baseOptions?: Apollo.QueryHookOptions<PageStreamQuery, PageStreamQueryVariables>) {
        return Apollo.useQuery<PageStreamQuery, PageStreamQueryVariables>(PageStreamDocument, baseOptions);
      }
export function usePageStreamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageStreamQuery, PageStreamQueryVariables>) {
          return Apollo.useLazyQuery<PageStreamQuery, PageStreamQueryVariables>(PageStreamDocument, baseOptions);
        }
export type PageStreamQueryHookResult = ReturnType<typeof usePageStreamQuery>;
export type PageStreamLazyQueryHookResult = ReturnType<typeof usePageStreamLazyQuery>;
export type PageStreamQueryResult = Apollo.QueryResult<PageStreamQuery, PageStreamQueryVariables>;
export const PageSubscriptionSettingsDocument = gql`
    query PageSubscriptionSettings {
  xyzPlus {
    ...XyzPlusStatusInformer
    currentSubscription {
      status
      ...PageSubscriptionCurrentSubscription
    }
  }
}
    ${XyzPlusStatusInformerFragmentDoc}
${PageSubscriptionCurrentSubscriptionFragmentDoc}`;

/**
 * __usePageSubscriptionSettingsQuery__
 *
 * To run a query within a React component, call `usePageSubscriptionSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageSubscriptionSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageSubscriptionSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageSubscriptionSettingsQuery(baseOptions?: Apollo.QueryHookOptions<PageSubscriptionSettingsQuery, PageSubscriptionSettingsQueryVariables>) {
        return Apollo.useQuery<PageSubscriptionSettingsQuery, PageSubscriptionSettingsQueryVariables>(PageSubscriptionSettingsDocument, baseOptions);
      }
export function usePageSubscriptionSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageSubscriptionSettingsQuery, PageSubscriptionSettingsQueryVariables>) {
          return Apollo.useLazyQuery<PageSubscriptionSettingsQuery, PageSubscriptionSettingsQueryVariables>(PageSubscriptionSettingsDocument, baseOptions);
        }
export type PageSubscriptionSettingsQueryHookResult = ReturnType<typeof usePageSubscriptionSettingsQuery>;
export type PageSubscriptionSettingsLazyQueryHookResult = ReturnType<typeof usePageSubscriptionSettingsLazyQuery>;
export type PageSubscriptionSettingsQueryResult = Apollo.QueryResult<PageSubscriptionSettingsQuery, PageSubscriptionSettingsQueryVariables>;
export const PageSubscriptionTariffsDocument = gql`
    query PageSubscriptionTariffs {
  xyzPlus {
    tariffs {
      ...PageSubscriptionTariffItem
    }
  }
}
    ${PageSubscriptionTariffItemFragmentDoc}`;

/**
 * __usePageSubscriptionTariffsQuery__
 *
 * To run a query within a React component, call `usePageSubscriptionTariffsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageSubscriptionTariffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageSubscriptionTariffsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageSubscriptionTariffsQuery(baseOptions?: Apollo.QueryHookOptions<PageSubscriptionTariffsQuery, PageSubscriptionTariffsQueryVariables>) {
        return Apollo.useQuery<PageSubscriptionTariffsQuery, PageSubscriptionTariffsQueryVariables>(PageSubscriptionTariffsDocument, baseOptions);
      }
export function usePageSubscriptionTariffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageSubscriptionTariffsQuery, PageSubscriptionTariffsQueryVariables>) {
          return Apollo.useLazyQuery<PageSubscriptionTariffsQuery, PageSubscriptionTariffsQueryVariables>(PageSubscriptionTariffsDocument, baseOptions);
        }
export type PageSubscriptionTariffsQueryHookResult = ReturnType<typeof usePageSubscriptionTariffsQuery>;
export type PageSubscriptionTariffsLazyQueryHookResult = ReturnType<typeof usePageSubscriptionTariffsLazyQuery>;
export type PageSubscriptionTariffsQueryResult = Apollo.QueryResult<PageSubscriptionTariffsQuery, PageSubscriptionTariffsQueryVariables>;
export const PageXyzPlusContentDocument = gql`
    query PageXyzPlusContent($id: ID!) {
  xyzPlusContent(id: $id) {
    title
    access {
      view
    }
    ...PageXyzPlusContentBreadCrumbs
    ...PageXyzPlusContentPreview
    ...PageXyzPlusContentControls
    ...PageXyzPlusContentBody
  }
}
    ${PageXyzPlusContentBreadCrumbsFragmentDoc}
${PageXyzPlusContentPreviewFragmentDoc}
${PageXyzPlusContentControlsFragmentDoc}
${PageXyzPlusContentBodyFragmentDoc}`;

/**
 * __usePageXyzPlusContentQuery__
 *
 * To run a query within a React component, call `usePageXyzPlusContentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageXyzPlusContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageXyzPlusContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePageXyzPlusContentQuery(baseOptions?: Apollo.QueryHookOptions<PageXyzPlusContentQuery, PageXyzPlusContentQueryVariables>) {
        return Apollo.useQuery<PageXyzPlusContentQuery, PageXyzPlusContentQueryVariables>(PageXyzPlusContentDocument, baseOptions);
      }
export function usePageXyzPlusContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageXyzPlusContentQuery, PageXyzPlusContentQueryVariables>) {
          return Apollo.useLazyQuery<PageXyzPlusContentQuery, PageXyzPlusContentQueryVariables>(PageXyzPlusContentDocument, baseOptions);
        }
export type PageXyzPlusContentQueryHookResult = ReturnType<typeof usePageXyzPlusContentQuery>;
export type PageXyzPlusContentLazyQueryHookResult = ReturnType<typeof usePageXyzPlusContentLazyQuery>;
export type PageXyzPlusContentQueryResult = Apollo.QueryResult<PageXyzPlusContentQuery, PageXyzPlusContentQueryVariables>;