import React from 'react';
import { gql } from '@apollo/client';

import { useTranslation } from 'react-i18next';
import { usePageCheckersDashboardQuery } from '@/generated/graphql';

import { Heading, List, GridContainer, Container, PageError404 } from '@xyz-school/xyz-react-frontend';

import CheckerAssignmentsList from '@/models/Assignments/CheckerAssignmentsList';

import styles from './PageCheckersDashboard.scss';

gql`
    query PageCheckersDashboard {
        currentUser {
            isStaff
        }
    }
`;

const PageCheckersDashboard = () => {
    const { data, loading } = usePageCheckersDashboardQuery();
    const { t } = useTranslation(['staff']);

    if (!loading && !data?.currentUser?.isStaff) {
        return <PageError404 />; // @todo: error 403
    }

    const checkersRulesList = [t('CHECKERS_RULES_1'), t('CHECKERS_RULES_2'), t('CHECKERS_RULES_3')];

    return (
        <GridContainer className={styles.pageWrap}>
            <Heading level={1}>{t('CHECKERS_DASHBOARD_TITLE')}</Heading>

            <Container paddingTop={6} paddingBottom={6}>
                <CheckerAssignmentsList />

                <Container paddingTop={6}>
                    <Container paddingBottom={1.5}>
                        <Heading level={3}>{t('CHECKERS_RULES_TITLE')}</Heading>
                    </Container>
                    <List type="unordered" items={checkersRulesList} />
                </Container>
            </Container>
        </GridContainer>
    );
};

export default PageCheckersDashboard;
