import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Container, Heading } from '@xyz-school/xyz-react-frontend';
import DiscordRequiredInformer from '@/models/User/DiscordRequiredInformer';
import TelegramCommonChannelInformer from '@/models/Telegram/TelegramCommonChannelInformer';
import { useFeatures } from '@/models/User/useFeature';

import Loading from '@/core/Loading';
import {
    useDashboardMyQuery,
    DashboardEnrollmentsListFragmentDoc,
    DashboardCoursesListFragmentDoc,
} from '@/generated/graphql';

import DashboardEnrollmentsList from '../DashboardEnrollmentsList';
import DashboardEmptyTab from '../DashboardEmptyTab';
import DashboardCoursesList from '../DashboardCoursesList';

import DashboardMySkeleton from './DashboardMySkeleton';

gql`
    query DashboardMy {
        enrollments {
            ...DashboardEnrollmentsList
        }
        currentUser {
            isDiscordRequired
        }
        recomendedCourses: courses(scope: "recommendation") {
            ...DashboardCoursesList
        }
        staffCourses: courses(scope: "staff") {
            ...DashboardCoursesList
        }
        reviewCourses: courses(scope: "checker") {
            ...DashboardCoursesList
        }
        ${DashboardEnrollmentsListFragmentDoc}
        ${DashboardCoursesListFragmentDoc}
    }
`;

type Props = {
    banner?: React.ReactNode;
};

const DashboardMy = ({ banner }: Props) => {
    const features = useFeatures();
    const { t } = useTranslation(['dashboard']);
    const { data, loading } = useDashboardMyQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const isEmpty: boolean =
        !data?.enrollments?.edges.length && !data?.reviewCourses?.edges.length && !data?.staffCourses?.edges.length;

    return (
        <>
            <Container marginBottom={6}>
                <Heading level={1}>{t('MY_COURSES')}</Heading>;
            </Container>

            {data?.currentUser?.isDiscordRequired && (
                <Container marginBottom={6}>
                    <DiscordRequiredInformer />
                </Container>
            )}

            <Container marginBottom={6}>
                <TelegramCommonChannelInformer />
            </Container>

            <Loading
                isLoading={loading}
                isEmpty={isEmpty}
                loadingComponent={<DashboardMySkeleton />}
                emptyComponent={
                    <DashboardEmptyTab title={t('ACTIVE_COURSES')}>{t('MY_COURSES_EMPTY')}</DashboardEmptyTab>
                }
            >
                {/* Я студент */}
                {!!data?.enrollments?.edges.length && (
                    <DashboardEnrollmentsList title={t('ACTIVE_COURSES')} enrollments={data.enrollments} />
                )}

                {/* Рекомендации */}
                {features.recomendedCourses && !!data?.recomendedCourses?.edges.length && (
                    <DashboardCoursesList
                        title={t('RECOMENDED_COURSES')}
                        listData={data.recomendedCourses}
                        isRecomended
                    />
                )}

                {/* Я проверяющий */}
                {!!data?.reviewCourses?.edges.length && (
                    <DashboardCoursesList title={t('IM_INSPECTOR')} listData={data.reviewCourses} />
                )}

                {/* Я персонал */}
                {!!data?.staffCourses?.edges.length && (
                    <DashboardCoursesList title={t('IM_STAFF')} listData={data.staffCourses} />
                )}
            </Loading>

            {banner && (
                <Container marginBottom={10} marginBottomXs={6}>
                    {banner}
                </Container>
            )}
        </>
    );
};

export default DashboardMy;
