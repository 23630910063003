import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@xyz-school/xyz-react-frontend';

import Informer from '@/ui/Informer';

const TelegramCommonChannelInformer = () => {
    const { t } = useTranslation(['telegram']);

    return (
        <Informer
            title={t('COMMON_CHANNEL_INFORMER_TITLE')}
            description={t('COMMON_CHANNEL_INFORMER_DESCRIPTION')}
            buttons={
                <Button linkTo="https://t.me/xyz_podcast" linkTarget="_blank" styleType="primary">
                    {t('COMMON_CHANNEL_INFORMER_LINK_TITLE')}
                </Button>
            }
        />
    );
};

export default TelegramCommonChannelInformer;
